.size_small {
    font-size: 12px;
}

.size_normal {
    font-size: 17px;
}

.size_big {
    font-size: 22px;
}