body,
html {
    margin: 0;
    padding: 0
}

/* opacity */

.opacity50 {
    opacity: .5;
}