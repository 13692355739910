.box {
    position: relative;
    z-index: 3;
    width: 40px;
    height: 40px;
}

.image {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-size: cover;
    background-color: gray;
    cursor: pointer;
}

.image.size_big{
    width: 80px;
    height: 80px;
}

.more {
    position: absolute;
    left: 10px;
    top: 50%;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-size: cover;
    background-color: green;
    cursor: pointer;
}